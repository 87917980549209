import React from 'react'
import { useCookies } from 'react-cookie'

import Icon from 'components/elements/Icon'

import { disableFoilLayer } from 'services/accountSettings.service'

import styles from './cardOverlays.module.scss'

type Props = {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  visible?: boolean
}

export const CardFilter = ({ children = null, className = '', visible = true, style = {} }: Props) => {
  const [internalVisibility, setInternalVisibility] = React.useState(visible)

  React.useEffect(() => {
    if (!visible) setTimeout(() => setInternalVisibility(true), 300)
    if (visible) setInternalVisibility(true)
  }, [visible])

  if (!internalVisibility) return null

  return (
    <div
      className={`${styles.cardFilter} ${className}`}
      style={{ opacity: internalVisibility && visible ? undefined : 0, ...style }}>
      {children}
    </div>
  )
}

export const FoilDiv = (props: Props) => {
  const [{ [disableFoilLayer]: foilLayerIsDisabled }] = useCookies([disableFoilLayer])

  if (foilLayerIsDisabled) return null

  return <CardFilter {...props} className={`${props.className || ''} ${styles.foil}`} />
}

export const ErrorCard = (props: Props) => (
  <CardFilter {...props} className={`${props.className || ''} ${styles.error}`} />
)

export const SecondaryCategory = (props: Props) => (
  <CardFilter {...props} className={`${props.className || ''} ${styles.secondaryCategory}`} />
)

export const MultiSelected = (props: Props & { simple?: boolean }) => (
  <>
    {!props.simple && (
      <CardFilter
        style={props.style}
        visible={props.visible}
        className={`${props.className || ''} ${styles.multiSelected}`}>
        {props.children}
        <Icon className={styles.multiSelectedCheck} name="check" />
      </CardFilter>
    )}

    <CardFilter
      style={props.style}
      visible={props.visible}
      className={`${props.className || ''} ${styles.multiSelectedOutline}`}
    />
  </>
)

export const AntiHighlight = (props: Props) => (
  <CardFilter {...props} className={`${props.className || ''} ${styles.filteredOut}`} />
)
