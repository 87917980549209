import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDragLayer } from 'react-dnd'

import { CardType } from 'types/deck'

import BasicCard from 'components/card/BasicCard'

import styles from './cursorCardWrapper.module.scss'

type Props = {
  children: React.ReactNode
  skipOnTouch?: boolean
  card: CardType
  flipped?: boolean
  className?: string
  cardClassName?: string
  onHoverStart?: () => void
  onHoverEnd?: () => void
  insideContainerQueryBlock?: boolean
}

const CursorCardWrapper = (props: Props) => {
  // If we're dragging anything, do _not_ show the hovered card (this may come back to bite us at some point)
  const { isDragging } = useDragLayer(monitor => ({ isDragging: monitor.isDragging() }))
  return <GenericCursorCardWrapper {...props} isDragging={isDragging} />
}

export const GenericCursorCardWrapper = ({
  children,
  card,
  className,
  cardClassName = '',
  skipOnTouch = false,
  flipped = false,
  onHoverStart,
  onHoverEnd,
  isDragging,
  insideContainerQueryBlock,
}: Props & { isDragging: boolean }) => {
  const [hover, setHover] = useState(false)
  const [viewable, setViewable] = useState(false)

  const touched = useRef(false)
  const delayRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const cardRef = useRef<any>(null)

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!cardRef.current) return

      const cardWidth = cardRef.current.clientWidth
      const cardHeight = cardRef.current.clientHeight

      const windowWidth = window.innerWidth || 0
      const windowHeight = window.innerHeight || 0

      let x = e.clientX
      let y = e.clientY

      if (insideContainerQueryBlock) {
        x = e.layerX
        y = e.layerY
      }

      if (x === 0 && y === 0) return

      const switchHoverToLeftOfCursor = e.clientX > windowWidth - cardWidth
      const switchHoverToTopOfCursor = e.clientY > windowHeight - cardHeight

      const xOffset = switchHoverToLeftOfCursor ? -1 * cardWidth - 25 : 25
      const yOffset = switchHoverToTopOfCursor ? y - cardHeight - 25 : y

      const transform = `translate(${x + xOffset}px, ${yOffset + 25}px)`

      cardRef.current.style.display = 'unset'
      cardRef.current.style.transform = transform
    },
    [hover],
  )

  const handleHoverStart = (e: React.MouseEvent) => {
    if (touched.current && skipOnTouch) return
    if (hover) return
    if (onHoverStart) onHoverStart()

    delayRef.current = setTimeout(() => {
      handleMouseMove(e.nativeEvent as MouseEvent) // Calling once directly because if the user exclusively scrolls with the mouse wheel, the mousemove event won't fire
      setViewable(true)
    }, 250)

    setHover(true)
  }

  const handleHoverEnd = () => {
    if (onHoverEnd) onHoverEnd()

    if (delayRef.current) clearTimeout(delayRef.current)

    setViewable(false)
    setHover(false)
  }

  useEffect(() => {
    if (!hover) return () => document.removeEventListener('mousemove', handleMouseMove)

    document.addEventListener('mousemove', handleMouseMove, false)
  }, [hover])

  return (
    <span
      className={className}
      onMouseOver={handleHoverStart}
      onMouseLeave={handleHoverEnd}
      onTouchStart={() => (touched.current = true)}
      // Delay is to be sure that touches are still considered touch on tap
      onTouchEnd={() => setTimeout(() => (touched.current = false), 100)}>
      {children}
      {hover && !isDragging && (
        <BasicCard
          flipped={flipped}
          ref={cardRef}
          className={`${styles.hoverCard} ${cardClassName}`}
          style={{ opacity: viewable && cardRef?.current?.style?.transform ? 1 : 0 }} // Sometimes the cardRefs transform is delayed, and it causes a flicker - just don't show anything until the transform is applied
          card={card}
          nameOnPlaceholder={false}
          textOnPlaceholder={false}
        />
      )}
    </span>
  )
}

export default CursorCardWrapper
